<template>
  <div>
    <a-button type="primary" @click="showModal"> 新建配置 </a-button>
    <a-modal
      :visible="visible"
      title="创建配置"
      @cancel="handleCancel"
      @create="handleCreate"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        @submit="handleSubmit"
      >
        <!--<a-form-item label="ID">
                  <a-input
                   placeholder="请输入产品ID" v-decorator="['id', { rules: [{ required: true, message: 'Please input yourhardWareID!' }] }]"
                  />
                </a-form-item>-->
        <a-form-item label="id">
          <a-select
            v-decorator="[
              'id',
              {
                rules: [
                  { required: true, message: 'Please select your gender!' },
                ],
              },
            ]"
            placeholder="在下面选一个选项"
            @change="handleSelectChange"
          >
            <a-select-option value="0"> 0 </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="name">
          <a-input
            placeholder="请输入报警名称"
            v-decorator="[
              'name',
              {
                rules: [
                  { required: true, message: 'Please input your name!' },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="dataId">
          <a-input
            placeholder="请输入数据id"
            v-decorator="[
              'dataId',
              {
                rules: [
                  { required: true, message: 'Please input your dataId!' },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="alarmCondition">
          <a-select
            v-decorator="[
              'alarmCondition',
              {
                rules: [
                  { required: true, message: 'Please select your alarmCondition!' },
                ],
              },
            ]"
            placeholder="在下面选一个选项"
            @change="handleSelectChange"
          >
            <a-select-option value=">"> 大于</a-select-option>
            <a-select-option value="<"> 小于</a-select-option>
            <a-select-option value="="> 等于 </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="value">
          <a-input
            placeholder="请输入触发阀值"
            v-decorator="[
              'value',
              {
                rules: [
                  { required: true, message: 'Please input your value!' },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="type">
          <a-select
            v-decorator="[
              'type',
              {
                rules: [
                  { required: true, message: 'Please select your gender!' },
                ],
              },
            ]"
            placeholder="选择模拟量数据类型"
            @change="handleSelectChange"
          >
            <a-select-option value="string"> string </a-select-option>
            <a-select-option value="int"> int </a-select-option>
            <a-select-option value="float"> float </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="primary" html-type="submit"> 确认 </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      mypath: "",
      visible: false,
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
    };
  },
  methods: {
    showModal() {
      this.visible = !this.visible;
    },
    // 取消填写表单
    handleCancel() {
      this.visible = false;
    },
    //  确认表单
    handleCreate() {
      this.visible = false;
    },
    handleSubmit(e) {
      let this_1 = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // let a = eval(values.status.toLowerCase())
          // console.log(typeof(a))
          //console.log('Received values of form: ', values);
          this_1.mypath = window.g.pro_alarm_set;

          axios({
            url: this_1.mypath,
            method: "post",
            //发送格式为json
            headers: {
              Authorization: localStorage.getItem("Authorization"),
            },
            data: {
              name: values.name,
              id: parseInt(values.id),
              dataId: parseInt(values.dataId),
              value:values.value,
              alarmCondition:values.alarmCondition,
              type: values.type,
            },
          }).then((response) => {
            this_1.visible = false;
            //  console.log(response.data.code)
            if (response.data.errorCode == 200) {
              this_1.$message.success("创建成功");
            } else {
              this_1.$message.error("创建失败");
            }
          });
        }
      });
    },
    handleSelectChange(value) {
      //  console.log(value);
      this.form.setFieldsValue({
        note: `Hi, ${value === "male" ? "man" : "lady"}!`,
      });
    },
  },
};
</script>