<template>
  <div style="background-color: #ececec; padding: 20px">
    <a-row :gutter="16">
      <a-col :span="8">
        <a-card title="全部产品" :bordered="false">
          <p>{{ mytotal.allnumber }}</p>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card title="在线" :bordered="false">
          <p>{{ mytotal.online }}</p>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card title="离线" :bordered="false">
          <p>{{ mytotal.off_line }}</p>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>



<script>
import axios from "axios";

export default {
  props: ["total"],
  name: "StatusTotal",
  data() {
    return {
      mytotal: {
        all: [],
        allnumber: 0,
        online: 0,
        off_line: 0,
        timer: null,
      },
    };
  },

  created() {
    this.mytimer();
  },

  methods: {
    mytimer() {
      let this_ = this;
      this_.timer = setInterval(function getdata() {
        this_.mypath = window.g.device_num;
        axios
          .get(this_.mypath, {
            headers: {
              Authorization: localStorage.getItem("Authorization"),
            },
          })
          .then((response) => {
            // console.log(response.data)
            // this.devises = response.data.data
            // console.log(typeof(response.data))
            if (response.data.errorCode === 2001) {
              this_.$message.error("权限认证失效，请重新登录");
              this_.$router.push("/Login");
            }
            this_.mytotal.allnumber = response.data.data.allCnt;
            this_.mytotal.online = response.data.data.connected;
            this_.mytotal.off_line = response.data.data.disconnect;
            //console.log(response.data)
          });
      }, 2000);
    },
  },
  watch: {
    total(n, o) {
      /*this.mytotal.all= n.map(item=>{
         return{
            mystate:item.status.toString()
         }
       })
       //console.log(this.mytotal.all)
       this.mytotal.allnumber=this.mytotal.all.length
       let online =0
      //   console.log("数组",this.mytotal.all[0])
       for(let i=0;i<this.mytotal.all.length;i++){
         if(this.mytotal.all[i].mystate=="true"){
           online=online+1
         }

       }*/
      // this.mytotal.online=online
      // this.mytotal.off_line=this.mytotal.all.length - online
    },
  },
  beforeDestroyed() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped>
.StatusTotal {
  padding: 0;
  margin: 20px 20px;
  width: 100%;
}
</style>