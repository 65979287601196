var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.showModal}},[_vm._v(" 新建配置 ")]),_c('a-modal',{attrs:{"visible":_vm.visible,"title":"创建配置"},on:{"cancel":_vm.handleCancel,"create":_vm.handleCreate}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"id"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'id',
            {
              rules: [
                { required: true, message: 'Please select your gender!' } ],
            } ]),expression:"[\n            'id',\n            {\n              rules: [\n                { required: true, message: 'Please select your gender!' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"在下面选一个选项"},on:{"change":_vm.handleSelectChange}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v(" 0 ")])],1)],1),_c('a-form-item',{attrs:{"label":"name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                { required: true, message: 'Please input your name!' } ],
            } ]),expression:"[\n            'name',\n            {\n              rules: [\n                { required: true, message: 'Please input your name!' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"请输入报警名称"}})],1),_c('a-form-item',{attrs:{"label":"dataId"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'dataId',
            {
              rules: [
                { required: true, message: 'Please input your dataId!' } ],
            } ]),expression:"[\n            'dataId',\n            {\n              rules: [\n                { required: true, message: 'Please input your dataId!' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"请输入数据id"}})],1),_c('a-form-item',{attrs:{"label":"alarmCondition"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'alarmCondition',
            {
              rules: [
                { required: true, message: 'Please select your alarmCondition!' } ],
            } ]),expression:"[\n            'alarmCondition',\n            {\n              rules: [\n                { required: true, message: 'Please select your alarmCondition!' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"在下面选一个选项"},on:{"change":_vm.handleSelectChange}},[_c('a-select-option',{attrs:{"value":">"}},[_vm._v(" 大于")]),_c('a-select-option',{attrs:{"value":"<"}},[_vm._v(" 小于")]),_c('a-select-option',{attrs:{"value":"="}},[_vm._v(" 等于 ")])],1)],1),_c('a-form-item',{attrs:{"label":"value"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'value',
            {
              rules: [
                { required: true, message: 'Please input your value!' } ],
            } ]),expression:"[\n            'value',\n            {\n              rules: [\n                { required: true, message: 'Please input your value!' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"请输入触发阀值"}})],1),_c('a-form-item',{attrs:{"label":"type"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'type',
            {
              rules: [
                { required: true, message: 'Please select your gender!' } ],
            } ]),expression:"[\n            'type',\n            {\n              rules: [\n                { required: true, message: 'Please select your gender!' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"选择模拟量数据类型"},on:{"change":_vm.handleSelectChange}},[_c('a-select-option',{attrs:{"value":"string"}},[_vm._v(" string ")]),_c('a-select-option',{attrs:{"value":"int"}},[_vm._v(" int ")]),_c('a-select-option',{attrs:{"value":"float"}},[_vm._v(" float ")])],1)],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 确认 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }